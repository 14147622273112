export default function getHiddenElementHeight(element, classToAdd?) {
  const clone = element.cloneNode(true);
  const data = element.getBoundingClientRect();
  clone.style.position = "absolute";
  clone.style.display = "block";
  clone.style.height = "auto";
  clone.style.width = data.width + "px";
  clone.classList.add(classToAdd === undefined ? "open" : classToAdd);
  clone.style.left = "-100000em";
  clone.id = "tmp-" + Math.floor(Math.random() * 99999);
  element.parentNode.appendChild(clone);
  const height = document.getElementById(clone.id)?.offsetHeight;
  clone.remove();
  return height;
}
