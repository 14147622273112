import { CalculatorType } from "../app.config";

interface Price {
  [CalculatorType.ONE_TIME]: Pricing;
  [CalculatorType.REGULAR]: Pricing;
}
interface Pricing {
  default_value: string;
  compartment: Array<{
    from: string;
    to: string;
    price: string;
  }>;
}

export default class Calculator {
  public count = (pricing: Price, multiplier: number, type: CalculatorType): number => {
    const typePricing = pricing[type];
    const default_value = typePricing.default_value;

    let price: null | number = null;

    typePricing.compartment.forEach((item) => {
      if (multiplier >= parseFloat(item.from) && multiplier <= parseFloat(item.to)) {
        price = parseFloat(item.price);
      }
    });

    if (!price) {
      price = parseFloat(default_value);
    }

    return price;
  };
}
