export default function setCookie(key: string, value: string, location: string, days: number) {
  let expires = "",
    path = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toString();
  }
  if (typeof location != "undefined" && location != "") {
    path = "; path=" + location;
  }
  document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
}
