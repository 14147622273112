import Lazy from "./libs/LazyLoad.lib";
import Header from "./components/Header";
import Accordions from "./sections/Accordions";
import OpinionsSlider from "./sections/OpinionsSlider";
import OfferTiles from "./sections/OfferTiles";
import RangeSlider from "./components/RangeSlider";
import InputFileHandler from "./components/InputFileHandler";

import { initSectionClass } from "./utils/initSectionClass";
import { MODAL_HASH } from "./app.config";
import Modal from "./components/Modal";

document.addEventListener("DOMContentLoaded", () => {
  new Lazy();

  new Header().init();

  handleInitContactModal();
  handleInitRangeSliders();
  initSectionClass(".accordions", Accordions);
  initSectionClass(".opinions_slider", OpinionsSlider);
  initSectionClass(".offer_tiles", OfferTiles);
  handleInitFileInputHandler();
});

const handleInitRangeSliders = async () => {
  const inputs = document.querySelectorAll<HTMLDivElement>(".range");

  //get next element sibling
  inputs.forEach((inputWrapper) => {
    const sibling = inputWrapper.nextElementSibling as HTMLInputElement;
    const connectedInput = sibling?.classList.contains("custom-range__number-input") ? sibling : null;
    new RangeSlider(inputWrapper, connectedInput).init();
  });
};

const handleInitContactModal = async () => {
  const modalWrapper = document.querySelector<HTMLElement>(".contact-modal");
  const modalClose = document.querySelector<HTMLElement>(".modal__close");

  if (modalWrapper) {
    new Modal(modalWrapper, MODAL_HASH, modalClose, "modal--visible").init();
  }
};

const handleInitFileInputHandler = async () => {
  const inputWrappers = document.querySelectorAll<HTMLInputElement>("label.file");

  inputWrappers.forEach((inputWrapper) => new InputFileHandler(inputWrapper).init());
};
