import LazyLoad from "vanilla-lazyload";

class LazyLoadImages {
  public lazy;
  constructor() {
    this.lazy = new LazyLoad({
      elements_selector: "img[data-src], .lazy, [data-bg]",
    });
  }
  update = () => {
    this.lazy.update();
  };
}

export default LazyLoadImages;
