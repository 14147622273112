import { opinionsSlider } from "../components/sliders";

export default class OpinionsSlider {
  private root: HTMLElement;
  private sliderWrapper: HTMLElement | null;

  constructor(root: HTMLElement) {
    this.root = root;
    this.sliderWrapper = this.root.querySelector<HTMLElement>(".splide");
  }

  init = async () => {
    this.initSlider();
  };

  initSlider = async () => {
    if (!this.sliderWrapper) return;

    opinionsSlider(this.sliderWrapper);
  };
}
