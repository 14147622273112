import Calculator from "../components/Calculator";
import Uiswipe from "../components/UiSwipe";
import { tilesSider } from "../components/sliders";
import { CalculatorType } from "../app.config";
export default class OfferTiles extends Calculator {
  private root: HTMLElement;
  private currentSquareFootageInput: HTMLInputElement | null;
  private tiles: NodeListOf<HTMLElement>;
  private typeSwitches: NodeListOf<HTMLInputElement>;

  constructor(root: HTMLElement) {
    super();

    this.root = root;
    this.currentSquareFootageInput = this.root.querySelector(".custom-range");
    this.tiles = this.root.querySelectorAll<HTMLElement>(".tile");
    this.typeSwitches = this.root.querySelectorAll<HTMLInputElement>(".switch  input");
  }

  init = async () => {
    if (!this.currentSquareFootageInput) return;

    this.currentSquareFootageInput.addEventListener("change", this.handleInputChange);
    this.typeSwitches.forEach((item) => item.addEventListener("change", this.handleInputChange));

    const sliderWrapper = this.root.querySelector<HTMLElement>(".splide");

    if (sliderWrapper) {
      const slides = sliderWrapper.querySelectorAll(".splide__slide");
      const uiSwipe = this.root.querySelector<HTMLElement>(".swipe-wrapper");

      const swipe = new Uiswipe(uiSwipe);
      swipe.init();

      tilesSider(sliderWrapper, slides.length, uiSwipe);

      document.addEventListener("slideChange", () => {
        console.log("change");
        swipe.toggle();
      });
    }
  };

  handleInputChange = () => {
    if (!this.currentSquareFootageInput) return;

    const currentSquareFootage = parseInt(this.currentSquareFootageInput.value);

    this.updateTiles(currentSquareFootage);
  };

  updateTiles = (currentSquareFootage: number) => {
    this.tiles.forEach((tile) => {
      const pricing = tile.dataset.pricing;
      const currentType = tile.querySelector<HTMLInputElement>(".switch  input");
      const priceWrapper = tile.querySelector(".tile__price");

      if (pricing === undefined && currentType) return;
      console.log(JSON.parse(pricing as string), "pricing");
      const currentPrice = this.count(
        JSON.parse(pricing as string),
        currentSquareFootage,
        currentType?.checked ? CalculatorType.REGULAR : CalculatorType.ONE_TIME
      );

      if (!priceWrapper) return;

      priceWrapper.innerHTML = currentPrice.toString();
    });
  };
}
