import { gsap } from "gsap";
import cookieGet from "../utils/cookieGet";
import cookieSet from "../utils/cookieSet";

export default class Uiswipe {
  private uiHelper: HTMLElement | null;

  constructor(swipe: HTMLElement | null) {
    this.uiHelper = swipe;
  }

  init = () => {
    if (!this.uiHelper) return;

    if (cookieGet(window.location.pathname + "ui-swipe")) {
      gsap.set(this.uiHelper, { opacity: 0 });
    }
  };

  public toggle = () => {
    cookieSet(window.location.pathname + "ui-swipe", "1", window.location.pathname, 30);
    gsap.to(this.uiHelper, { opacity: 0, duration: ".3" });
  };
}
