import gsap from "gsap";
import disableScroll from "../utils/disableScroll";
import enableScroll from "../utils/enableScroll";

export default class Modal {
  private timeLine: gsap.core.Timeline;
  private overlay: HTMLElement | null;

  constructor(
    private modalWrapper: HTMLElement,
    private readonly openingHash: string,
    private close: HTMLElement | null,
    private readonly activeClass: string
  ) {
    this.timeLine = this.getTimeLine() as gsap.core.Timeline;
    this.overlay = modalWrapper.querySelector(".modal__overlay");
  }

  init = async () => {
    if (window.location.hash === this.openingHash) {
      this.handleHashChange();
    }

    window.addEventListener("hashchange", this.handleHashChange);

    this.close?.addEventListener("click", this.closeModal);
    this.overlay?.addEventListener("click", this.closeModal);
  };

  handleHashChange = async () => {
    const { openingHash } = this;

    window.location.hash === openingHash ? this.openModal() : this.closeModal();
  };

  openModal = () => {
    const { modalWrapper, activeClass, timeLine } = this;

    modalWrapper.classList.add(activeClass);

    timeLine.play();
    disableScroll();
  };

  closeModal = () => {
    const { modalWrapper, activeClass } = this;

    this.timeLine.reverse();
    history.pushState("", document.title, window.location.pathname);
    modalWrapper.classList.remove(activeClass);
    enableScroll();
  };

  getTimeLine = () => {
    const { modalWrapper } = this;
    const content = modalWrapper.querySelector(".modal__modal");

    const tl = gsap.timeline({ paused: true });
    gsap.set(content, { opacity: 0, y: 100 });

    tl.to(modalWrapper, { duration: 0, opacity: 1 });

    tl.to(content, { duration: 0.5, opacity: 1, y: 0 }, "0.3");

    return tl;
  };
}
