import rangesliderJs from "rangeslider-js";

export default class RangeSlider {
  private inputWrapper: HTMLDivElement;
  private input: HTMLInputElement;
  private connectedInput: HTMLInputElement | null;

  constructor(inputWrapper: HTMLDivElement, connectedInput: HTMLInputElement | null) {
    this.inputWrapper = inputWrapper;
    this.input = this.inputWrapper.querySelector(".custom-range") as HTMLInputElement;
    this.connectedInput = connectedInput;
  }

  init = async () => {
    const { min, max, value } = this.getValues();

    this.initRangeSlider(min, max, value);

    if (this.connectedInput) {
      this.connectedInput.addEventListener("change", (e) => {
        const target = e.target as HTMLInputElement;
        const targetValue = parseInt(target.value);

        value < min ? (target.value = min.toString()) : null;
        value > max ? (target.value = max.toString()) : null;

        this.input["rangeslider-js"].update({ value: targetValue });
      });
    }
  };

  initRangeSlider = (min: number, max: number, value: number) => {
    new rangesliderJs.create(this.input, {
      min: min,
      max: max,
      value: value,
      step: 1,
      onSlide: (value: number) => {
        if (this.connectedInput) {
          this.connectedInput.value = value.toString();
        }
      },
    });
  };

  getValues = () => {
    const input = this.inputWrapper.querySelector<HTMLInputElement>(".custom-range");

    if (!input) return { min: 0, max: 2000, value: 50 };

    const min = parseInt(input.min);
    const max = parseInt(input.max);
    const value = parseInt(input.value);

    return { min, max, value };
  };
}
