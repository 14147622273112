export default class InputFileHandler {
  private input: HTMLInputElement | null;
  private fileNameWrapper: HTMLElement | null;

  constructor(private wrapper: HTMLElement) {
    this.input = this.wrapper.querySelector("input[type='file']");
    this.fileNameWrapper = this.wrapper.querySelector(".file__name-wrapper");
  }

  init = async () => {
    this.input?.addEventListener("change", this.handleFileChange);
  };

  handleFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files) {
      const fileName = files[0].name;
      this.fileNameWrapper ? (this.fileNameWrapper.innerText = fileName) : null;
    } else {
      this.fileNameWrapper ? (this.fileNameWrapper.innerText = "") : null;
    }
  };
}
