import gsap from "gsap";
import getHiddenElementHeight from "../utils/getHiddenElementHeight";

export default class Accordions {
  private root: HTMLElement;
  private accordions: Array<HTMLElement>;
  private currentOpen: HTMLElement | null;

  constructor(root: HTMLElement) {
    this.root = root;
    this.accordions = [...this.root.querySelectorAll<HTMLElement>(".accordion")];
    this.currentOpen = null;
  }

  public init = async (): Promise<void> => {
    this.accordions.forEach((accordion) => {
      accordion.addEventListener("click", this.handleAccordionClick);
    });

    const opened = this.accordions.find((accordion) => accordion.classList.contains("accordion--open"));

    if (opened) {
      this.openAccordion(opened);
      this.currentOpen = opened;
    }
  };

  private handleAccordionClick = (e: Event): void => {
    const target = e.currentTarget as HTMLElement;

    target.classList.contains("accordion--open") ? this.closeAccordion(target) : this.openAccordion(target);

    this.currentOpen ? this.closeAccordion(this.currentOpen) : null;

    this.currentOpen === target ? (this.currentOpen = null) : (this.currentOpen = target);
  };

  private openAccordion = async (accordion: HTMLElement): Promise<void> => {
    const { bottom_bar, minus, body } = this.getAnimatedElements(accordion);

    if (!bottom_bar || !minus || !body) return;

    accordion.classList.add("accordion--open");
    gsap.to(bottom_bar, { width: 0, duration: 0.5 });
    gsap.to(minus, { y: "-100%", opacity: 0, duration: 0.5 });

    setTimeout(() => {
      gsap.to(body, { height: getHiddenElementHeight(body), duration: 0.3, ease: "ease-out" });
    }, 200);
  };

  private closeAccordion = async (accordion: HTMLElement) => {
    const { bottom_bar, minus, body } = this.getAnimatedElements(accordion);

    if (!bottom_bar || !minus || !body) return;

    accordion.classList.remove("accordion--open");

    gsap.to(body, { height: 0, duration: 0.3, ease: "ease-out" });

    setTimeout(() => {
      gsap.to(bottom_bar, { width: "100%", duration: 0.5 });
      gsap.to(minus, { y: 0, opacity: 1, duration: 0.5 });
    }, 200);
  };

  private getAnimatedElements = (parent: HTMLElement) => {
    const bottom_bar = parent.querySelector<HTMLElement>(".accordion__bottom");
    const minus = parent.querySelector<HTMLElement>(".minus-bar");
    const body = parent.querySelector<HTMLElement>(".accordion__body");

    return { bottom_bar, minus, body };
  };
}
