import Splide from "@splidejs/splide";
import "@splidejs/splide/css/core";

export const opinionsSlider = (wrapper: HTMLElement) => {
  new Splide(wrapper, {
    // fixedWidth: 606,
    pagination: false,
    perPage: 3,
    gap: 15,
    breakpoints: {
      2500: {
        fixedWidth: 606,
        perPage: 1,
      },
      1024: {
        fixedWidth: 500,
      },
      580: {
        fixedWidth: 400,
      },
      420: {
        fixedWidth: 320,
      },
    },
  }).mount();
};

export const tilesSider = (wrapper: HTMLElement, slidesCount: number, uiSwipe: HTMLElement | null) => {
  const actLikeSlider = (count: number): boolean => {
    if (!slidesCount) return true;
    return count < slidesCount ? true : false;
  };

  new Splide(wrapper, {
    perPage: 3,
    arrows: false,
    pagination: false,
    drag: actLikeSlider(3),
    gap: 24,
    breakpoints: {
      1024: {
        perPage: 2,
        drag: actLikeSlider(2),
      },
      700: {
        perPage: 1,
        drag: actLikeSlider(1),
      },
    },
  })
    .on("moved", () => {
      //emmit new slideChange event
      const event = new CustomEvent("slideChange");
      document.dispatchEvent(event);
    })
    .on("mounted", () => {
      if (!uiSwipe) return;
      setTimeout(() => {
        wrapper.classList.contains("splide--draggable") ? uiSwipe.classList.remove("hidden") : uiSwipe.classList.add("hidden");
      }, 10);
    })
    .on("updated", (e) => {
      if (!uiSwipe) return;
      setTimeout(() => {
        wrapper.classList.contains("splide--draggable") ? uiSwipe.classList.remove("hidden") : uiSwipe.classList.add("hidden");
      }, 10);
    })
    .mount();
};

export const titleDescriptionSlider = (element: HTMLElement, slidesCount: number) => {
  const actLikeSlider = (count: number): boolean => {
    if (!slidesCount) return true;
    return count < slidesCount ? true : false;
  };

  new Splide(element, {
    perPage: 3,
    arrows: actLikeSlider(3),
    drag: actLikeSlider(3),
    gap: 24,
    breakpoints: {
      1500: {
        gap: 16,
      },
      1280: {
        perPage: 2,
        arrows: actLikeSlider(2),
        drag: actLikeSlider(2),
        gap: 8,
      },
      800: {
        perPage: 1,
        arrows: actLikeSlider(1),
        drag: actLikeSlider(1),
      },
    },
  }).mount();
};
